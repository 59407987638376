* {
    font-family: 'Source Sans Pro', sans-serif;
}

html {
    height: 100%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    position: relative;
    margin: 0;
    padding-bottom: 6rem;
    min-height: 100%;
}

.demo {
    margin: 0 auto;
    padding-top: 64px;
    max-width: 640px;
    width: 94%;
}

:root {
    --black: #000;
    --green-teal: #0eba5e;
    --blueish: #1575a9;
    --sapphire: #2533b6;
    --white: #ffffff;
    --greyish-brown: #494949;
    --grey: #f4f4f4;
    --green1: #1b9aaa;
    --green2: #06d6a0;
    --orange: #F98724;
}

.app-container {
    margin-top: 5px;
}


a.regular-link,
.date {
    color: #315E90;
    font-size: 14px;
}

/* Login */

div.login-container {
    height: 81vh;
    background: url(../images/background_login.jpg) no-repeat center bottom fixed;
    background-size: 100% 60%;
}

input.login-input {
    margin-bottom: 10px;
}

.login-label {
    color: #626262;
}

/* Navbar */

.navbar-content {
    display: grid;
    grid-template-columns: 200px 1fr;
    height: 100%;
    grid-column: 2;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-top: 5px;
}

.navbar-header {
    font-weight: 600;
    min-width: 170px;
}

.nav-logo-container {
    align-items: center;
    display: flex;
    padding: 0 0 0 15px;
}

.nav-logo {
    width: 100%;
}

.nav-link {
    cursor: pointer;
}

.nav-links-group {
    list-style-type: none;
    margin: 0;
}

.nav-link-element,
.nav-link,
.dropdown-item {
    color: #315E90 !important;
}

.nav-link-element {
    display: inline-block;
}

.nav-link-element.dropdown.nav-item {
    padding-top: 10px;
}

.nav-link-element.nav-link.username {
    padding: 15px 20px 0 0;
}

.login-column.logo {
    text-align: center;
    clear: right
}

.dropdown-menu .dropdown-item {
    padding: .25rem 0;
}

.dropdown-menu {
    padding: 0 !important;
}

.navbar-header.dropdown-toggle::after {
    float: right;
    margin-top: .5625em;
}

/* Breadcrumbs */
.breadcrumb {
    background: transparent !important;
    font-size: 22px;
}

.breadcrumb-item {
    font-weight: bold;
}

.breadcrumb-item.active,
.breadcrumb-item+.breadcrumb-item::before {
    color: #315E90 !important;
}

.principal.breadcrumb-item,
.principal.breadcrumb-item a {
    color: var(--orange) !important;
}

/* Tables */

.table-cell,
.table-header {
    color: #315E90;
}

button.table-link {
    background: none;
    color: #F98724 !important;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
}

button.table-link:hover,
button.table-link:active {
    background: none !important;
}

button.table-link:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 !important;
}

button.table-link:disabled {
    color: graytext !important;
    pointer-events: none;
    cursor: default;
}

a.table-link {
    color: #F98724 !important;
    cursor: pointer;
}

a.table-link.disabled {
    color: grey !important;
}

a.table-link.policy.disabled {
    pointer-events: none;
    cursor: default;
    color: #CCCCCC !important;
}

.table-header {
    font-weight: 600;
    font-size: 14px;
    padding: 0 5px 2px;
    white-space: pre-line !important;
    word-wrap: break-word;
}

.table-cell {
    font-size: 14px;
    padding: 5px 5px 5px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-cell.wrap {
    white-space: normal;
    word-break: break-word;
}

.alert {
    margin-bottom: 0 !important;
    padding: 0 !important;
    color: white !important;
}

.alert-success {
    background-color: #39CA74 !important;
    border-color: #39CA74 !important;
}

.alert-primary {
    background-color: #00BFFF !important;
    border-color: #00BFFF !important;
}

.alert-warning {
    background-color: #DCB84A !important;
    border-color: #DCB84A !important;
}

.alert-danger {
    background-color: grey !important;
    border-color: grey !important;
}

.alert-dark-blue {
    background-color: #315E90 !important;
    border-color: #315E90 !important;
}

.filter-select {
    white-space: normal;
}

div.rt-thead.-filters {
    font-size: 14px;
}

/* ReactTable */

.rt-thead.-filters {
    background: #EFEFEF;
}

.ReactTable {
    border: 1px solid #CCCCCC;
}

.ReactTable .rt-thead.-filters .rt-th {
    border: none;
}

.ReactTable .-sort-desc {
    box-shadow: none !important;
}

.ReactTable .rt-th.-cursor-pointer:before {
    font-family: 'Font Awesome\ 5 Free';
    content: "\f0dc";
    color: #315E90;
    float: right;
    font-weight: 900;
}

.ReactTable .-sort-asc {
    box-shadow: none !important;
}

.ReactTable .-sort-asc:before,
.ReactTable .rt-th.-cursor-pointer:before {
    font-family: 'Font Awesome\ 5 Free';
    content: "\f0dc";
    color: #315E90;
    float: right;
    margin-top: 3px;
    margin-right: 1px;
    font-size: 14px;
    font-weight: 900;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    padding: 9px 7px;
}

.policy-list-table .ReactTable .rt-thead.-filters .rt-th {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.policy-list-table .rthfc .-filters .rt-th.rthfc-th-fixed-left-last {
    border-right: solid 1px #ccc;
}

#filiales-beneficiarios-table-container {
    width: 99.99%;
}

#filiales-beneficiarios-table-container .ReactTable .rt-table,
#filiales-beneficiarios-table-container .ReactTable .rt-table .rt-td {
    overflow: visible;
}

.overflow-visible {
    overflow: visible;
}

/* React Select */
.Select {
    z-index: 100;
}

.Select.is-open {
    z-index: 999;
}

/* Forms */

.form-section label,
.form-section .form-title,
.form-section .fa-chevron-right,
.form-section .fa-check-circle,
.form-values,
.small-form-value {
    color: #315E90;
}

.form-section .fa-times-circle {
    color: #F98724;
}

.form-title {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.27px;
    line-height: 25px;
}

.form-values {
    margin-top: 5px;
    font-weight: normal;
}

.small-form-value {
    margin-top: 5px;
    font-weight: normal;
    font-size: 14px;
    color: #315E90;
}

.small-text {
    font-size: 14px;
    color: #315E90;
}

.pre-wrap-white-space {
    white-space: pre-wrap;
}

.no-margin-top {
    margin-top: 0;
}

.download,
button.download,
button.nav-link,
button.download:active {
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
}

button.nav-link {
    margin: 8px 5px;
}

.download,
button.download,
button.download:active {
    color: #F98724 !important;
}

.download:focus {
    outline: none;
}

.form-separator {
    height: 1px;
    color: #F98724;
    background-color: #F98724;
    border: none;
    margin: 0 0 15px 0;
}

.form-section label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.19px;
    line-height: 18px;
}

.custom-policy-select {
    font-size: 14px;
    color: #626262;
}

#intermediario,
#asegurado,
#afianzado {
    min-width: 300px;
}

.form-control {
    font-size: 14px !important;
    color: #626262;
}

div.size-warning {
    color: #315E90;
    font-size: 14px;
    margin-top: 10px;
}

.has-error>.custom-policy-select>div:first-child {
    border-color: #dc3545;
}

/* Endosos */
@media (min-width: 992px) {
    .modal-lg {
        max-width: 700px !important;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 850px !important;
    }
}

.modal-dialog.modal-bottom {
    max-width: 100% !important;
    bottom: 0;
    position: fixed;
    width: 100% !important;
    margin-bottom: 0;
}

.endoso-dropdown .dropdown-menu {
    border: 1px solid #F98724;
}

button.dropdown-toggle.btn.btn-secondary {
    box-shadow: 0 0 !important;
}

.endoso-dropdown div button.dropdown-item {
    padding: 5px 0 !important;
    white-space: normal;
}

.endoso-dropdown .dropdown-menu .dropdown-divider {
    margin: 0 !important;
}

.endoso-dropdown button.dropdown-toggle,
.endoso-dropdown.show button.dropdown-toggle,
.endoso-dropdown button.dropdown-toggle:active {
    background-color: white !important;
    box-shadow: 0 0 0 0;
    color: #F98724 !important;
    border: 1px solid #F98724 !important;
}

.endoso-dropdown button.dropdown-toggle:after {
    margin-left: 50px !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: whitesmoke !important;
}

button.modal-button.btn.btn-secondary:active,
button.modal-button.btn.btn-secondary,
button.modal-button.btn.btn-secondary:focus {
    background-color: transparent !important;
    width: 100% !important;
    color: #F98724 !important;
    box-shadow: 0 0 0 0;
    border: none !important;
    text-align: left !important;
}

button.modal-button.btn.btn-secondary:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.endoso-form.modal-header {
    border-bottom: none;
    padding: 6px 20px;
}

.endoso-form .form-group {
    margin-bottom: 5px !important;
}

.endoso-form.modal-header button.close {
    font-size: 45px;
    font-weight: 200;
    color: #E67B2E;
    opacity: inherit;
}

.endoso-form.modal-header button:focus {
    outline: none !important;
}

.endoso-form.modal-header button span:focus {
    outline: none !important;
}

.modal-body {
    padding: 10px 50px !important;
}

.modal-footer {
    padding: 0 40px 25px !important;
}

.endoso-form.modal-footer {
    border: none !important;
    display: inline-block !important;
}

h5.modal-title {
    color: #315E90;
    font-weight: bold;
    margin-top: 20px;
    padding: 0 15px;
}

.endoso-form label,
.endoso-form p {
    color: #315E90 !important;
    font-size: 14px;
}

.endoso-form label {
    font-weight: 600;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus,
button.close.delete:hover {
    box-shadow: 0 0 0 0 !important;
}

.endoso-form p {
    margin-top: 7px;
}

.text-muted {
    color: #CCCCCC !important;
}

button.close.delete {
    margin-top: -12px;
}

button.close.delete:focus {
    outline: none !important;
}

.warning-button {
    color: #D32D2D;
    margin-top: 2px;
}

.modal-dialog.modal-warning.warning-container.modal-lg {
    padding: 0 !important;
}

.warning-container,
.warning-container div.button-container {
    display: flex;
}

div.button-container {
    justify-content: space-around;
}

div.button-container .orange,
div.button-container .orange:active {
    background: #F98724 !important;
    border: none;
    font-size: 14px;
    width: 170px;
}

div.button-container .white,
div.button-container .white:active {
    background: #FFFFFF !important;
    border-color: #F98724;
    color: #F98724;
    font-size: 14px;
    width: 170px;
}

.warning-container {
    padding: 30px 20px !important;
    justify-content: center;
}

.warning-container div.warning-section {
    margin: 0 40px;
}

.warning-container div.warning-section.text {
    display: flex;
    padding: 4px 0 0 0;
    color: #626262;
    font-weight: bold;
}

.warning-container div.warning-section.text div {
    margin: 0 2px;
    vertical-align: middle;
}

.warning-container div.warning-section.text .icon-container {
    color: #F98724;
    font-size: 22px;
    margin: -4px 10px 0 0;
}

.warning-container div.button-container div {
    margin: 0 8px;
}

button.warning-cancel,
button.warning-publish {
    font-weight: bold;
    font-size: 14px;
    color: white;
    width: 165px;
    border-radius: 6px;
}

button.warning-cancel {
    background: #315E90;
    border-color: #315E90;
}

button.warning-publish {
    background: #F98724;
    border-color: #F98724;
}

#delete-file {
    background: none;
    color: #315E90;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
    margin: 15px 0;
    font-size: 14px;
}

/*Footer*/

.footer {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 30px 45px;
    width: 100%;
    background-color: #292B33;
    letter-spacing: -0.22px;
    line-height: 18px;
    color: #CCCCCC;
    font-size: 14px;
    text-align: center;
}

/* Buttons */

.btn-orsan {
    width: 100%;
    background: var(--orange) !important;
    border: var(--orange) !important;
    color: #fff !important;
}

.btn-orsan:not(:disabled):not(.disabled):hover {
    background: #e47c21 !important;
    border: #e47c21 !important;
}

.btn-orsan:not(:disabled):not(.disabled):active,
.btn-orsan:not(:disabled):not(.disabled).active {
    background-color: #d2690e !important;
    border-color: #d2690e !important;
}

.btn-orsan:focus,
.btn-orsan.focus {
    box-shadow: 0 0 0 0.2rem #d2690e80 !important;
}


a.cancel-btn,
a.cancel-btn:hover {
    background: transparent;
    color: #F98724;
    border: 1px solid #F98724;
    width: 100%;
}

button.link-btn {
    background: none !important;
    border: none;
    color: #F98724 !important;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
}

div.label {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.19px;
    line-height: 18px;
    color: #315E90;
}

.error-msg-small {
    color: #dc3545;
    font-size: 14px;
}

div.cp-form-feedback {
    margin-top: 40px;
}

div.cp-form-feedback p {
    color: #dc3545;
    min-height: 24px;
}

.error-color {
    color: #dc3545 !important;
}

div.plan-pago-feedback {
    margin-bottom: 10px;
}

div.plan-pago-feedback p {
    color: #dc3545 !important;
    font-size: 14px;
    margin: 0;
}

/* Radio button with input text -------------------------- Begin */

div.radio-with-input-in-the-middle,
div.radio-with-input-at-the-end {
    padding-bottom: 6px;
    line-height: 35px;
}

div.radio-with-input-in-the-middle label.form-check-label,
div.radio-with-input-at-the-end label.form-check-label {
    margin-bottom: 0;
}

div.radio-with-input-at-the-end input[type=radio] {
    margin-top: 3px;
}

div.radio-with-input-at-the-end input[type=text] {
    display: inline-block;
    width: inherit;
    float: right;
}

div.radio-with-input-in-the-middle input[type=radio] {
    margin-top: 11px;
}

div.radio-with-input-in-the-middle input[type=text] {
    display: inline-block;
    width: inherit;
    margin: 0 5px
}

input.form-control:disabled,
input.form-control[readonly],
.custom-file-input:disabled~.custom-file-label {
    background-color: hsl(0, 0%, 95%) !important;
    border-color: hsl(0, 0%, 90%);
    color: hsl(0, 0%, 60%);
    opacity: 1;
}

label.custom-file-label {
    overflow: hidden;
    font-weight: normal;
}

/* Radio button with input text ---------------------------- End */
/* Radio button with react-select ------------------------ Begin */

div.radio-with-select-in-the-middle label.form-check-label,
div.radio-with-select-at-the-end label.form-check-label {
    margin-bottom: 0;
}

div.radio-with-select-in-the-middle input[type=radio],
div.radio-with-select-at-the-end input[type=radio] {
    margin-top: 3px;
}

/* Radio button with react-select -------------------------- End */
/* Inline text with input -------------------------------- Begin */

div.inline-text-with-input input {
    display: inline-block;
}

/* Inline text with input ---------------------------------- End */

/* Accordion --------------------------------------------- Begin */

.accordion-section-header.expansible:hover {
    background: #F3F3F3;
}

.env-message {
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: .75rem 1.25rem;
    /* default staging values */
    border: 2px dashed #F59A11;
    background-color: #F4B454;
    color: white;
}