
/*------------------------------------------- general --------------------------------------------*/

.btn-full-width {
  width: 100%;
}

/*---------------------------------------- orsan-primary -----------------------------------------*/
.btn-orsan-primary {
  color: #fff;
  background-color: var(--orange);
  border-color: var(--orange);
}

.btn-orsan-primary:hover {
  color: #fff;
  background-color: #e47c21;
  border-color: #e47c21;
}

.btn-orsan-primary:focus, .btn-orsan-primary.focus {
  box-shadow: 0 0 0 0.2rem #d2690e80;
}

.btn-orsan-primary.disabled, .btn-orsan-primary:disabled {
  color: #fff;
  background-color: var(--orange);
  border-color: var(--orange);
}

.btn-orsan-primary:not(:disabled):not(.disabled):active, .btn-orsan-primary:not(:disabled):not(.disabled).active,
.show > .btn-orsan-primary.dropdown-toggle {
  color: #fff;
  background-color: #e47c21;
  border-color: #d2690e;
}

.btn-orsan-primary:not(:disabled):not(.disabled):active:focus, .btn-orsan-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-orsan-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #d2690e80;
}

/*--------------------------------------- orsan-secondary ----------------------------------------*/
.btn-orsan-secondary {
  color: #fff;
  background-color: #315e90;
  border-color: #315e90;
}

.btn-orsan-secondary:hover {
  color: #fff;
  background-color: #2b527d;
  border-color: #24456a;
}

.btn-orsan-secondary:focus, .btn-orsan-secondary.focus {
  box-shadow: none;
}

.btn-orsan-secondary.disabled, .btn-orsan-secondary:disabled {
  color: #fff;
  background-color: #315e90;
  border-color: #315e90;
}

.btn-orsan-secondary:not(:disabled):not(.disabled):active, .btn-orsan-secondary:not(:disabled):not(.disabled).active,
.show > .btn-orsan-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2b527d;
  border-color: #24456a;
}

.btn-orsan-secondary:not(:disabled):not(.disabled):active:focus, .btn-orsan-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-orsan-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

/*------------------------------------------ orsan-link ------------------------------------------*/
.btn-orsan-link {
  font-weight: 400;
  color: var(--orange);
  text-decoration: none;
}

.btn-orsan-link:hover {
  color: #e47c21;
  text-decoration: underline;
}

.btn-orsan-link:focus, .btn-orsan-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-orsan-link:disabled, .btn-orsan-link.disabled {
  color: #6c757d;
  pointer-events: none;
}


/*------------------------------------ outline-orsan-primary -------------------------------------*/
.btn-outline-orsan-primary {
  color: var(--orange);
  border-color: var(--orange);
}

.btn-outline-orsan-primary:hover {
  color: var(--orange);
  border-color: var(--orange);
}

/* Over copiandose de bootstrap */
/*
.btn-outline-orsan-primary:hover {
  color: #fff;
  background-color: var(--orange);
  border-color: var(--orange);
}
 */

.btn-outline-orsan-primary:focus, .btn-outline-orsan-primary.focus {
  box-shadow: 0 0 0 0.2rem #d2690e80;
}

.btn-outline-orsan-primary.disabled, .btn-outline-orsan-primary:disabled {
  color: var(--orange);
  background-color: transparent;
}

.btn-outline-orsan-primary:not(:disabled):not(.disabled):active, .btn-outline-orsan-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-orsan-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--orange);
  border-color: var(--orange);
}

.btn-outline-orsan-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-orsan-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orsan-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #d2690e80;
}
