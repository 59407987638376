
.ReactTable .rt-th {
    overflow: visible;
}

.ReactTable .rt-thead .rt-tr {
    text-align: left;
}

.expand-2 {
    grid-column: 1 / 3;
}
