
div.file-table-container {
    border: 1px solid #ddd;
    border-radius: 4px;
}

table.file-table {
    border-collapse: collapse;
    overflow: hidden;
    border-spacing: 0;
    width: 100%;
}

table.file-table td.filename {
    font-size: 14px;
    color: #315E90;
}

table.file-table tr td:nth-child(1n) {
    width: 90%;
    padding: 5px 10px;
}

table.file-table tr td:nth-child(2n) {
    width: 5%;
    padding: 5px 10px 5px 0;
}

table.file-table tr td:nth-child(3n) {
    width: 5%;
    padding: 5px 10px 5px 0;
}

table.file-table tr:nth-child(even) {
    background-color: #f1f1f1
}

table.read-only tr td:nth-child(2n) {
    text-align: right;
}

.file-upload {
    margin-top: 15px;
}

.file-upload input:focus,
.file-upload:focus {
    outline: none !important;
}

.file-upload input[type="file"] {
    display: none;
}

.file-upload label {
    border: 1px solid #CCCCCC;
    background-color: #EFEFEF;
    color: #626262 !important;
    display: table;
    font-size: 12px !important;
    padding: 4px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.file-upload div.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.file-save {
    margin-top: 13px;
}

.file-save > button {
    float: right;
    padding: 4px 30px;
}

.reactstrap-table-link {
    color: #F98724 !important;
    padding: 0 !important;
}
